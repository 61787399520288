






































































































































import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import TabMixin from "@/mixins/TabMixin";
import FscPageHeader from "@/components/FscPageHeader.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import Validation from "@/components/Validation.vue";
import { Validations } from "vuelidate-property-decorators";
import { email, required } from "vuelidate/lib/validators";
import { namespace } from "vuex-class";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import { ICountry } from "@/interfaces/ICountry";
import PlzInput from "@/components/Field/PlzInput.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import FscPlaces from "@/components/Field/FscPlaces.vue";
import FscInputIban from "@/components/Field/FscInputIban.vue";
import { bic, iban } from "@/validators/validators";
import { IStudentEducation } from "@/interfaces/IStudentEducation";

const CountryModule = namespace("country");
const LivePayMandateModule = namespace("live-pay-mandate");

@Component({
  components: {
    FscInputIban,
    FscPlaces,
    SaveButton,
    AbortButton,
    PlzInput,
    Validation,
    FscSimpleCard,
    FscPageHeader,
  },
})
export default class Mandate extends mixins(TabMixin) {
  public name = "Mandate";

  public country = null;
  public company = null;
  public firstName = null;
  public lastName = null;
  public iban = null;
  public postalCode = null;
  public streetAndNumber = null;
  public email = null;
  public location = null;
  public bic = null;

  @CountryModule.Action("findAll")
  public findAllCountries!: (options: ICrudOptions) => Promise<void>;

  @CountryModule.Getter("getDataList")
  public countries!: Array<ICountry>;

  @LivePayMandateModule.Getter("getSuccess")
  public livePayMandateSuccess!: boolean;

  @LivePayMandateModule.Getter("getIsLoading")
  public livePayMandateLoading!: boolean;

  @LivePayMandateModule.Action("create")
  public createLivePayMandate!: (options: ICrudOptions) => Promise<void>;

  @Prop()
  public education!: any;

  protected studentEducation: IStudentEducation | null = null;

  public mounted(): void {
    if (!this.$route.params.setUp) {
      this.$nextTick(() => {
        this.closeCurrentTab();
      });
      return;
    }

    if (this.education) {
      this.studentEducation = this.education;
    }

    this.findAllCountries({
      resource: "countries",
      params: { archived: false },
    });
  }

  @Validations()
  public validations(): Record<string, any> {
    return {
      country: { required },
      firstName: { required },
      lastName: { required },
      iban: { required, iban },
      postalCode: { required },
      streetAndNumber: { required },
      email: { required, email },
      location: { required },
      bic: { required, bic },
    };
  }

  protected onAbort(): void {
    this.$nextTick(() => {
      this.closeCurrentTab();
    });
  }

  protected async onSubmit(): Promise<void> {
    this.$v.$touch();

    if (this.$v.$invalid || !this.studentEducation) return;

    const { id } = this.studentEducation;

    await this.createLivePayMandate({
      resource: "live-pay-mandate",
      data: {
        studentEducationId: id,
        country: this.country,
        company: this.company,
        firstName: this.firstName,
        lastName: this.lastName,
        iban: this.iban,
        postalCode: this.postalCode,
        location: this.location,
        streetAndNumber: this.streetAndNumber,
        email: this.email,
        documentId: null,
        revocationDocumentId: null,
        bic: this.bic,
      },
    });

    if (this.livePayMandateSuccess) {
      this.$root.$emit("refresh-student-education");
      this.$root.$emit("refresh-mandate-info");
      this.closeCurrentTab();
    }
  }
}
