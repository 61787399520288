var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column h-100"},[_c('b-overlay',{staticClass:"h-100 d-flex flex-column",attrs:{"show":_vm.livePayMandateLoading,"rounded":"md"}},[_c('b-row',[_c('b-col',{staticClass:"my-32",attrs:{"lg":"12"}},[_c('div',{staticClass:"d-flex"},[_c('fsc-page-header',{staticClass:"border-0",attrs:{"remove-spacing":true}},[_c('span',{staticClass:"pr-4 border-right py-1"},[_vm._v(" "+_vm._s(_vm.$t("sidebar.students"))+" ")]),_c('span',{staticClass:"pl-4 py-1"},[_vm._v(" "+_vm._s(_vm.$t("general.new_mandate"))+" ")])])],1)])],1),_c('b-row',{staticClass:"h-100 overflow-hidden"},[_c('b-col',{staticClass:"h-100 d-flex flex-column",attrs:{"sm":"6"}},[_c('fsc-simple-card',{staticClass:"h-100",scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',{staticClass:"font-weight-bold font-size-20 mb-2"},[_vm._v(" "+_vm._s(_vm.$t("general.new_mandate"))+" ")])]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"btn-container mr-0"},[_c('abort-button',{staticClass:"ml-2 fsc-btn-border",attrs:{"label":""},on:{"click":function($event){return _vm.onAbort()}}}),_c('save-button',{staticClass:"ml-2 fsc-btn-border",attrs:{"label":_vm.$t('general.save'),"loading":_vm.livePayMandateLoading},on:{"click":function($event){return _vm.onSubmit()}}})],1)]},proxy:true}])},[_c('b-row',[_c('b-col',{staticClass:"form-group",attrs:{"sm":"6"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("general.country"))+" * ")]),_c('validation',{attrs:{"validations":_vm.$v.country},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('fsc-multiselect',{class:{ multiselect__invalid: invalid },attrs:{"options":_vm.countries,"label":"nameInGerman","searchable":true},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}})}}])})],1),_c('b-col',{staticClass:"form-group",attrs:{"sm":"12"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("sidebar.company"))+" ")]),_c('b-form-input',{model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}})],1),_c('b-col',{staticClass:"form-group",attrs:{"sm":"6"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("user.first_name"))+" * ")]),_c('validation',{attrs:{"validations":_vm.$v.firstName},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('b-form-input',{class:{ 'is-invalid': invalid },model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})}}])})],1),_c('b-col',{staticClass:"form-group",attrs:{"sm":"6"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("user.last_name"))+" * ")]),_c('validation',{attrs:{"validations":_vm.$v.lastName},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('b-form-input',{class:{ 'is-invalid': invalid },model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})}}])})],1),_c('b-col',{staticClass:"form-group",attrs:{"sm":"8"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("master_data.iban"))+" * ")]),_c('validation',{attrs:{"validations":_vm.$v.iban},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('FscInputIban',{class:{ 'is-invalid': invalid },model:{value:(_vm.iban),callback:function ($$v) {_vm.iban=$$v},expression:"iban"}})}}])})],1),_c('b-col',{staticClass:"form-group",attrs:{"sm":"4"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("master_data.bic"))+" * ")]),_c('validation',{attrs:{"validations":_vm.$v.bic},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('b-form-input',{class:{ 'is-invalid': invalid },model:{value:(_vm.bic),callback:function ($$v) {_vm.bic=$$v},expression:"bic"}})}}])})],1),_c('b-col',{staticClass:"form-group",attrs:{"sm":"4"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("user.post_code"))+" * ")]),_c('validation',{attrs:{"validations":_vm.$v.postalCode},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('PlzInput',{class:{ 'is-invalid': invalid },model:{value:(_vm.postalCode),callback:function ($$v) {_vm.postalCode=$$v},expression:"postalCode"}})}}])})],1),_c('b-col',{staticClass:"form-group",attrs:{"sm":"4"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("user.city"))+" * ")]),_c('validation',{attrs:{"validations":_vm.$v.location},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('fsc-places',{staticClass:"form-control ap-input",class:{ 'form-control ap-input is-invalid': invalid },model:{value:(_vm.location),callback:function ($$v) {_vm.location=$$v},expression:"location"}})}}])})],1),_c('b-col',{staticClass:"form-group",attrs:{"sm":"4"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("user.street_no"))+" * ")]),_c('validation',{attrs:{"validations":_vm.$v.streetAndNumber},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('b-form-input',{class:{ 'is-invalid': invalid },model:{value:(_vm.streetAndNumber),callback:function ($$v) {_vm.streetAndNumber=$$v},expression:"streetAndNumber"}})}}])})],1),_c('b-col',{staticClass:"mb-4",attrs:{"sm":"12"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("user.email"))+" * ")]),_c('validation',{attrs:{"validations":_vm.$v.email},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('b-form-input',{class:{ 'is-invalid': invalid },model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})}}])})],1),_c('b-col',{staticClass:"form-group",attrs:{"sm":"1"}},[_c('font-awesome-icon',{attrs:{"icon":"lightbulb-on"}})],1),_c('b-col',{staticClass:"form-group font-size-14",attrs:{"sm":"11"}},[_c('span',{staticClass:"d-block mb-2"},[_vm._v(" "+_vm._s(_vm.$t("messages.mandate_info1"))+" ")]),_c('span',{staticClass:"d-block"},[_vm._v(" "+_vm._s(_vm.$t("messages.mandate_info2"))+" ")])])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }